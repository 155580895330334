<template>
  <div id="stats">
    <div
      v-if="sidebar"
      class="sidebar"
    >
      <div
        v-if="!isLoading"
        v-html="sidebar.contenu"
      />
      <InfiniteLoader v-else />
      <ButtonClassic
        v-if="sidebarModal"
        id="more_filters"
        color="primary"
        icon="left"
        :label="$t('stats.voir-plus-de-filtres')"
        @click="$modal.show('modal_sidebar')"
      >
        <template #left-icon>
          <UilPlus />
        </template>
      </ButtonClassic>
    </div>
    <div id="content">
      <TabsGroup
        :tabs="tabs"
        @change="changeTab($event)"
      />
      <div id="main_content">
        <div
          v-if="!isLoading"
          v-html="currentTab?.contenu"
        />
        <InfiniteLoader v-else />
      </div>
    </div>

    <Modal
      v-if="sidebarModal"
      id="modal_sidebar"
    >
      <template #title>
        {{ $t("stats.plus-de-filtres") }}
      </template>
      <template #content>
        <div
          v-if="!isLoading"
          v-html="sidebarModal.contenu"
        />
        <InfiniteLoader v-else />
      </template>
    </Modal>
  </div>
</template>

<script>

import {
  TabsGroup,
  ButtonClassic,
  Modal,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import { UilPlus } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

export default {
  name: "Stats",
  components: {
    TabsGroup,
    ButtonClassic,
    Modal,
    InfiniteLoader,
    UilPlus,
  },
  data() {
    return {
      allTabs: [],
      isLoading: false,
    };
  },
  computed: {
    sidebar() {
      return this.allTabs.find((t) => t.nom === "sidebar");
    },
    sidebarModal() {
      return this.allTabs.find((t) => t.nom === "sidebar-modal");
    },
    tabs() {
      return this.allTabs
        .filter((tab) => !tab.nom.includes("sidebar"))
        .map((tab) => ({ ...tab, active: tab.key === this.$route.query.tab }));
    },
    currentTab() {
      return this.tabs.find((tab) => tab.active === true);
    },
  },
  mounted() {
    this.searchTabs();
  },
  methods: {
    /**
     * Recherche les contenu des onglets.
     */
    searchTabs() {
      this.isLoading = true;

      const host = "https://1qvb5lfmkypgc9f.eu.qlikcloud.com";
      const webIntegrationId = "TbJ7TqDs0xNKjiNNhJ6IYy_fm35LcvH-";
      const returnTo = `${window.top.location.href}?ok=true`;
      const loginUrl = `${host}/login?qlik-web-integration-id=${webIntegrationId}&returnto=${returnTo}`;

      if (window.location.href.includes("ok=true")) {
        Api().get(`onglet-stats?espace=${this.$route.params.name}`)
          .then(({ data }) => {
            this.allTabs = data.map((tab) => ({
              ...tab,
              key: tab.id.toString(),
              title: tab.nom,
              routerLinkObj: {
                name: this.$route.name,
                query: { ...this.$route.query, tab: tab.id.toString() },
              },
            }));

            if (!this.$route.query.tab) {
              this.$router.push({ query: { ...this.$route.query, tab: this.tabs[0].key } });
            }

            this.isLoading = false;
          });
      } else {
        window.top.location.href = loginUrl;
      }
    },
    /**
     * Affiche le contenu d'un onglet.
     */
    changeTab() {
      this.searchTabs();
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_stats.scss";
</style>
